<template>
    <div class="about">
        <div id="nav2">
            <dataNav></dataNav>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import dataNav from '@/components/nav.vue'
    export default {
        name:'Count',
        components:{
            dataNav
        }
    }
</script>
<style scoped>
    #nav2{
        position: fixed;
        top: 0;
        left: 50%;
        margin-left: -18.75rem;
        z-index: 4;
    }
</style>