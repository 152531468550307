<template>
  <div class="nav">
    <ul>
      <!--            <router-link :to="v.linkTo" tag="li" class="nav-item" v-for="(v,i) in navList" v-if="v.hasPerm"-->
      <!--                         v-text="v.val" :key="i"></router-link>-->
      <li v-for="(v,i) in navList" :key="i">
        <router-link :to="v.linkTo" class="nav-item" v-if="v.hasPerm && !v.linkFlg"
                     v-text="v.val"></router-link>
        <a class="nav-item" v-if="v.hasPerm && v.linkFlg" :href="v.linkTo+'?token='+token" v-text="v.val" target="_blank"></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "data-nav",
  data () {
    return {
      permId: [],
      // {id: 5, val: '返奖', linkTo: ''},
      navList: [{id: 1, val: '销量', linkTo: '/count/sales', hasPerm: false},
        {id: 2, val: '赛事', linkTo: '/count/match', hasPerm: false}, {
          id: 3,
          val: '活动',
          linkTo: '/count/activity',
          hasPerm: false
        }, {
          id: 4,
          val: '门店', linkTo: '/count/store', hasPerm: false
        }, {
          id: 5,
          val: '周报', linkTo: this.pageUrl+'index.html', hasPerm: false, linkFlg: true
        }],
      token: this.getCookie('token') || '',
      bjFlg: this.getCookie('userPerm').indexOf('6') > -1
    }
  },
  methods: {
    getUserPerm () {
      let permList = this.getCookie('userPerm').split(',');
      for (let i in permList) {
        for (let j in this.navList) {
          if (this.navList[j].id == permList[i]) {
            this.navList[j].hasPerm = true
          }
        }
      }
      if(!this.bjFlg){
        this.navList.splice(4,1)
      }
    }
  },
  mounted () {
    this.getUserPerm();
  },
}
</script>

<style scoped>
.nav {
  width: 37.5rem;
  height: 4.5rem;
  background: rgba(0, 84, 224, 1);
}
.nav ul{
  width: 100%;
  display: flex;
  align-items: center;
}
.nav ul li{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav ul .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 4.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: rgba(169, 199, 249, 1);
}

.nav-item.router-link-active {
  color: rgba(255, 255, 255, 1) !important;
}
</style>